import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import euroGIF from "../gifs/euro.gif";
import quillGIF from "../gifs/quill.gif";
import tractorGIF from "../gifs/tractor.gif";

const Box = styled.div`
  margin-bottom: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Icons = styled.div`
  display: flex;
  background: white;
  padding: 1em;
`;

const Icon = styled.div`
  &:not(:last-of-type) {
    margin-right: 12px;
  }
`;

const iconSize = 75;

const Nav = ({ children }) => (
  <>
    <Box>
      <Icons>
        <Icon>
          <Link to="/sponsors">
            <img src={euroGIF} alt="Euro" width={iconSize} height={iconSize} />
          </Link>
        </Icon>
        <Icon>
          <Link to="/writings-on-global-cows">
            <img
              src={quillGIF}
              alt="Quill"
              width={iconSize}
              height={iconSize}
            />
          </Link>
        </Icon>
        <Icon>
          <a href="https://imagesfestival.com" target="_blank">
            <img
              src={tractorGIF}
              alt="Tractor"
              width={iconSize}
              height={iconSize}
            />
          </a>
        </Icon>
      </Icons>
    </Box>
  </>
);

export default Nav;
