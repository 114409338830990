import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { device } from "../device";
import Marquee from "react-fast-marquee";
import Layout from "../components/Layout";
import Nav from "../components/Nav";
import cowsay from "cowsay2";

const body = {
  marquee:
    "CONCEIVED UNDER THE UDDER SUN | STEAMING ENTRAILS MULCH MOTION LIPSEAL GORSE BORDERS | VAPOURMATIC RUSTICO LUXURY | DIGEST AND CONTAIN IN UTTER FURY | BUTTER EARTHOPTICS | PULLED CUSTARDS | PUMPS LIMPID CONTINENTAL COLLAPSE",
};

const Box = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: 100px 1fr 120px;
  justify-content: center;
`;

const Title = styled.h1`
  margin-top: auto;
  text-align: center;
  font-family: "Basteleur", sans-serif;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 100%;
  padding: 0.75em;
  white-space: nowrap;
  span {
    color: #006633;
    padding-left: 1em;
    padding-right: 1em;
    text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px,
      #006633 0px 0px 20px, #006633 0px 0px 30px, #006633 0px 0px 40px,
      #006633 0px 0px 50px, #006633 0px 0px 75px;
  }
`;

const Subtitle = styled.h3`
  margin-top: 1em;
  text-align: center;
  font-family: "Basteleur", sans-serif;
  font-size: 2.5rem;
  a {
    color: #1bfcf8;
    text-decoration: none;
  }
  span {
    padding: 0.25em;
  }
`;

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const MarqueeStyled = styled(Marquee)`
  position: absolute !important;
  top: 40vh;
  padding-top: 1em;
  padding-bottom: 1em;
`;

const MarqueeText = styled.span`
  margin-right: 0.75em;
  height: 100%;
  font-size: 4rem;
  font-family: Helvetica, Arial, sans-serif;
  color: #1bfcf8;
  @media ${device.laptop} {
    font-size: 4rem;
  }
`;

const IndexPage = ({ data }) => {
  console.log(cowsay.think("Moo! I'm global"));
  return (
    <Layout>
      <MarqueeStyled gradient={false}>
        <MarqueeText>{body.marquee}</MarqueeText>
      </MarqueeStyled>
      <Box>
        <Container>
          <StyledImg fluid={data.hooves.fluid} />
        </Container>
        <Subtitle>
          <Link to="/exhibition">
            <span>Enter the Fresco</span>
          </Link>
        </Subtitle>
        <Title>
          <span>Global Cows 2020</span>
        </Title>
        <Nav />
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query {
    hooves: imageSharp(fluid: { originalName: { eq: "hooves.png" } }) {
      id
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export default IndexPage;

// fixed(base64Width: 1220) {
//   ...GatsbyImageSharpFixed
// }
// sexyCowLegs: imageSharp(
//   fluid: { originalName: { eq: "sexy-cow-legs.png" } }
// ) {
//   id
//   fluid {
//     ...GatsbyImageSharpFluid
//   }
// }
